import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <link rel="canonical" href="https://liveinbrisbane.com.au/blog/" />
        <title>Informations | Live in Brisbane</title>
        <meta name="description" content='Explore our information section to discover everything about life in Brisbane. From must-visit landmarks, local festivals, food and wine experiences, lifestyle, housing tips to cultural insights, our blogs are a comprehensive guide for anyone living in or planning to move to Brisbane.'/>      
      </Helmet>
      <div
        className="blog-list-container grid grid-cols-2 gap-4 mt-8"
        style={{ listStyle: `none` }}
      >
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <div className="blog-list" key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link
                      className="text-lg font-bold no-underline text-black hover:underline"
                      to={post.fields.slug}
                      itemProp="url"
                    >
                      <img
                        className="max-w-sm mx-5 hover:opacity-80"
                        src={post.frontmatter.frontimage}
                      />
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    className="max-w-xl"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="All posts" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          frontimage
        }
      }
    }
  }
`
